/**
 * 新模式单独的路由
 */
export const newModeRoute = [
  // ------ 新模式 登录后的逻辑 开始 ---------
  {
    path: '/select-role',
    name: 'selectRole',
    component: () => import('@/views/login/mode/selectBcompanySubject.vue'),
    meta: {
      title: '选择身份',
    },
  },
  {
    path: '/select-subject',
    name: 'selectSubject',
    component: () => import('@/views/login/mode/SelectCloudSubject.vue'),
    meta: {
      title: '选择主体',
    },
  },
  {
    path: '/new-mine',
    name: 'newMineView',
    component: () => import('@/views/newModel/MineView'),
    meta: {
      title: '我的',
    },
  },
  {
    path: '/new-upDownBillList',
    name: 'new-upDownBillList',
    component: () => import('@/views/newModel/upDownBillList'),
    meta: {
      title: '上下账列表',
    },
  },
  {
    path: '/new-upBillAudit',
    name: 'new-upBillAudit',
    component: () => import('@/views/newModel/audit'),
    meta: {
      title: '上下账详情',
    },
  },
  {
    name: 'new-upBillApplication',
    path: '/new-upBillApplication',
    component: () => import('@/views/newModel/upBillApplication'),
    meta: {
      title: '申请上账',
    },
  },
  {
    name: 'new-downBillApplication',
    path: '/new-downBillApplication',
    component: () => import('@/views/newModel/downBillApplication'),
    meta: {
      title: '申请退款',
    },
  },
  {
    path: '/new-myCustomer',
    name: 'new-myCustomer',
    component: () => import('@/views/newModel/myCustomer'),
    meta: {
      title: '我的客户',
    },
  },
  {
    path: '/new-myCustomer-audit',
    name: 'new-myCustomer-audit',
    component: () => import('@/views/newModel/customer-audit.vue'),
    meta: {
      title: '客户审核',
    },
  },
  {
    name: 'new-myWallet',
    path: '/new-myWallet/:type',
    component: () => import('@/views/newModel/myWallet/index'),
    meta: {
      title: '混拿货款',
    },
  },
  {
    name: 'new-billDetail',
    path: '/new-billDetail',
    component: () => import('@/views/newModel/myWallet/billDetail'),
    meta: {
      title: '本人余额',
    },
  },
  {
    name: 'new-cashSpringWaterParticulars',
    path: '/new-cashSpringWaterParticulars',
    component: () =>
      import('@/views/newModel/myWallet/cashSpringWaterParticulars'),
    meta: {
      title: '余额详情',
    },
  },
  {
    name: 'new-lockBalance',
    path: '/new-lockBalance',
    component: () => import('@/views/newModel/myWallet/lockBalance'),
    meta: {
      title: '锁定余额',
    },
  },
  {
    name: 'new-customerFlow',
    path: '/new-customerFlow',
    component: () => import('@/views/newModel/myWallet/customerFlow'),
    meta: {
      title: '客户流水',
    },
  },
  {
    name: 'new-postage',
    path: '/new-postage',
    component: () => import('@/views/newModel/postage/index'),
    meta: {
      title: '用邮费(消耗)',
    },
  },
  {
    name: 'new-postageDetail',
    path: '/new-postageDetail',
    component: () => import('@/views/newModel/postage/postageDetail'),
    meta: {
      title: '用邮费(消耗)变动明细',
    },
  },
  {
    name: 'new-postageParticulars',
    path: '/new-postageParticulars',
    component: () => import('@/views/newModel/postage/postageParticulars'),
    meta: {
      title: '用邮费(消耗)详情',
    },
  },
  {
    name: 'change-detail',
    path: '/change-detail/:type',
    component: () => import('@/views/newModel/changeDetail/index'),
    meta: {
      title: '详情',
    },
  },
  {
    path: '/new-goods',
    name: 'new-goods',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '@/views/newModel/goods/GoodsView.vue'
      ),
    meta: {
      title: '分类',
      keepAlive: true,
      depth: 0,
    },
  },
  {
    name: 'new-topUpActivity', // 首页公司大促列表
    path: '/new-topUpActivity/index',
    component: () => import('@/views/newModel/topUpActivity/index'),
    meta: {
      title: '充值活动',
      keepAlive: true,
    },
  },
  {
    name: 'selectStallNew', // 首页公司大促列表
    path: '/topUpActivity/selectStall-new',
    component: () => import('@/views/newModel/topUpActivity/selectStall.vue'),
    meta: {
      title: '选档',
    },
  },
  {
    name: 'new-rightsParticulars', // 首页公司大促列表
    path: '/new-topUpActivity/rightsParticulars',
    component: () => import('@/views/newModel/topUpActivity/rightsParticulars'),
    meta: {
      title: '权益详情',
    },
  },
  {
    name: 'new-coupons',
    path: '/new-coupons',
    component: () => import('@/views/newModel/coupons/list.vue'),
    meta: {
      title: '提货券',
    },
  },
  {
    name: 'convention',
    path: '/convention',
    component: () => import('@/views/newModel/coupons/convention.vue'),
    meta: {
      title: '填写参会人信息',
    },
  },
  {
    name: 'allocationRecord',
    path: '/allocationRecord',
    component: () => import('@/views/newModel/coupons/allocationRecord.vue'),
    meta: {
      title: '分配记录',
    },
  },
  {
    name: 'new-use-coupons',
    path: '/new-use-coupons',
    component: () => import('@/views/newModel/coupons/useNow.vue'),
    meta: {
      title: '使用提货券',
    },
  },
  {
    name: 'wx-login',
    path: '/wx-login',
    component: () => import('@/views/login/wxLogin.vue'),
    meta: {
      title: '登录',
      keepAlive: true,
      depth: 2,
    },
  },
  {
    name: 'phone-login',
    path: '/phone-login',
    component: () => import('@/views/login/phoneLogin.vue'),
    meta: {
      title: '登录',
      keepAlive: true,
      depth: 2,
    },
  },
  {
    name: 'login-privacy',
    path: '/login-privacy',
    component: () => import('@/views/login/privacyPolicy.vue'),
    meta: {
      title: '隐私协议',
      keepAlive: true,
      depth: 2,
    },
  },
  {
    name: 'invite-register',
    path: '/invite-register',
    component: () => import('@/views/inviteRegister/index.vue'),
    meta: {
      title: '注册',
      keepAlive: true,
      depth: 2,
    },
  },
  {
    name: 'register-success',
    path: '/register-success',
    component: () =>
      import('@/views/inviteRegister/components/registerSuccess.vue'),
    meta: {
      title: '注册',
      keepAlive: true,
      depth: 2,
    },
  },
  {
    name: 'loginManage',
    path: '/loginManage',
    component: () => import('@/views/myCenterAndBank/loginManage'),
    meta: {
      title: '登录管理',
    },
  },
  {
    name: 'toggleAccount',
    path: '/toggleAccount',
    component: () => import('@/views/myCenterAndBank/toggleAccount'),
    meta: {
      title: '切换手机号',
    },
  },
  {
    name: 'customerToAudit',
    path: '/customerToAudit',
    component: () => import('@/views/auditView/customerToAudit'),
    meta: {
      title: '查看详情',
    },
  },
  {
    name: 'customerToEdit',
    path: '/customerToEdit',
    component: () => import('@/views/auditView/customerToEdit'),
    meta: {
      title: '修改信息',
    },
  },
  {
    name: 'newStyles',
    path: '/newStyles',
    component: () => import('@/newStyles/index.vue'),
    meta: {
      title: '新样式',
    },
  },

  // ------ 新模式 登录后的逻辑 结束 ---------
]

// ------ 爆品模式  ---------
export const boomModeRoute = [
  {
    path: '/select-Mode',
    name: 'selectMode',
    component: () => import('@/views/login/SelectMode.vue'),
    meta: {
      title: '选择模式',
    },
  },
  {
    path: '/boom-home',
    name: 'boomHome',
    component: () => import('@/views/boomMode/home/Index.vue'),
    meta: {
      title: '首页',
    },
  },
  {
    path: '/boom-shopping-cart',
    name: 'boomShoppingCart',
    component: () => import('@/views/boomMode/shoppingCart/index.vue'),
    meta: {
      title: '购物车',
    },
  },
  {
    path: '/boom-coupon',
    name: 'boom-coupon',
    component: () => import('@/views/boomMode/myCoupon.vue'),
    meta: {
      title: '提货券',
    },
  },

  {
    path: '/boom-mine',
    name: 'boomMineView',
    component: () => import('@/views/boomMode/MineView'),
    meta: {
      title: '我的',
    },
  },
  {
    path: '/boom-order-create',
    name: 'boomOrderCreate',
    component: () => import('@/views/boomMode/createOrder'),
    meta: {
      title: '创建订单',
      keepAlive: true,
      depth: 2,
    },
  },
  {
    path: '/order-confirm',
    name: 'orderConfirm',
    component: () => import('@/views/newModel/purchaseOrderNew/orderConfirm'),
    meta: {
      title: '订单确认',
    },
  },
  {
    path: '/autograph',
    name: 'autograph',
    component: () => import('@/components/autograph.vue'),
    meta: {
      title: '签名',
    },
  },
  {
    path: '/orderQuery',
    name: 'orderQuery',
    component: () => import('@/views/newModel/orderQuery'),
    meta: {
      title: '订单查询',
    },
  },
  {
    path: '/orderQuery/detail',
    name: 'orderQueryDetail',
    component: () => import('@/views/newModel/orderQuery/detail.vue'),
    meta: {
      title: '物流信息',
    },
  },
]
